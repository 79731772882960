import React, { useState } from "react";
import axios from "axios";
import { Ring } from "react-awesome-spinners";
import { useForm } from "react-hook-form";
import { Link } from "gatsby";

import TextError from "../../components/notifications/TextError";

import Success from "../../components/notifications/SuccessMessage";
import Error from "../../components/notifications/Error";
import Layout from "../../components/layout/layout";

import SEO from "../../components/seo";

const posts = [
  {
    title: "",
    href: "https://www.tal.sg/tafep/contact-us",
    category: { name: "Article", href: "#" },
    description: "",
    date: "Mar 16, 2020",
    datetime: "2020-03-16",
    imageUrl: "",
    readingTime: "",
    author: {
      name: "",
      href: "#",
      imageUrl: "",
    },
  },
  {
    title: "How to use search engine optimization to drive sales",
    href: "#",
    category: { name: "Video", href: "#" },
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit facilis asperiores porro quaerat doloribus, eveniet dolore. Adipisci tempora aut inventore optio animi., tempore temporibus quo laudantium.",
    date: "Mar 10, 2020",
    datetime: "2020-03-10",
    imageUrl:
      "https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80",
    readingTime: "4 min",
    author: {
      name: "Brenna Goyette",
      href: "#",
      imageUrl:
        "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
    },
  },
];

const ContactForm = () => {
  const [errorText, setErrorText] = useState("");
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");
  const {
    register,
    handleSubmit,
    watch,

    formState: { errors, isDirty, isSubmitted, isSubmitting },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      honey: "",
      name: "",
      company: "",
      email: "",
      phone: "",
      message: "",
    },
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
  });

  // if (errors) {
  //   console.log("errors: ", errors);
  // }

  let count;

  if (watch("message")) {
    count = watch("message").length;
  } else {
    count = 0;
  }

  const onSubmit = async data => {
    if (data.honey !== "") {
      setErrorText("Spam suspected");
      return;
    }
    delete data.honey;
    console.log("submitted data: ", data);

    let airtableURL;
    let awsURL;

    if (data) {
      airtableURL = `${process.env.GATSBY_API_STATIC}/addcontact`;
      // airtableURL = `${process.env.REACT_APP_API_STATIC}/addcontact`;
      // awsURL = `${process.env.REACT_APP_AWS_API}/send-contact`;
    }
    try {
      setErrorText("");
      setLoading(true);
      // const res = await axios.post(awsURL, data);
      const res = await axios.post(airtableURL, data);
      console.log("contact res", res);
      if (res.data.statusCode === 400) {
        setErrorText(res.data.message);
        setLoading(false);
      } else if (res.data.statusCode === 200) {
        setResult("Submitted successfully");
        setLoading(false);
      }
    } catch (err) {
      setErrorText(err.message + "We have encountered a technical issue and your form was not submitted. Please email us directly at contactus@singaporewomenassociation.org"  );
      setLoading(false);
    }
  };

  return (
    <>
      <div className="absolute w-full h-32 z-n10 bg-gradient-to-r from-swa-1 to-swa-5"></div>
      <Layout>
        <SEO title="Contact Us" />
        <iframe
          src="https://forms.office.com/Pages/ResponsePage.aspx?id=LldTO-My3kiR_RDMZhsmk1aQJsU-DiVCkgvds6Nz4ZxUMEdTQ0IxS0Q5QU9DNUIwVTZQODFJRTJXTS4u&embed=true"
          frameBorder="0"
          marginWidth="0"
          marginHeight="0"
          style={{
            width: "100%",
            height: "1500px",
            border: "none",
            maxWidth: "100%",
            maxHeight: "100%",
          }}
          allowFullScreen
        />
        {/* <iframe width="640px" height="480px" src="https://forms.office.com/Pages/ResponsePage.aspx?id=LldTO-My3kiR_RDMZhsmk1aQJsU-DiVCkgvds6Nz4ZxUMEdTQ0IxS0Q5QU9DNUIwVTZQODFJRTJXTS4u&embed=true" frameborder="0" marginwidth="0" marginheight="0" style="border: none; max-width:100%; max-height:100vh" allowfullscreen webkitallowfullscreen mozallowfullscreen msallowfullscreen> </iframe> */}
        <div className="mx-3 m-14  md:mx-auto md:max-w-xl lg:max-w-2xl">
          {/* <div className="px-1 pb-5 ">

            <h1 className="text-swa-3">Online enquiry form</h1>
            <p className="max-w-2xl mt-1 text-gray-500">
              For enquiry about community projects, sponsorships, volunteering
              and other general matters
            </p>
       
            {errorText && <Error msg={errorText} />}
            {isSubmitted && result && (
              <Success msg={result}>
                We will respond as soon as possible.
              </Success>
            )}
            {!isSubmitted && errorText === "" && result === "" && (
              <form
                className="max-w-xl mt-5 space-y-5"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
                  <div className="pt-4 space-y-6 sm:pt-4 sm:space-y-5">
                    <div>
                      <h3 className="text-lg font-bold leading-6 text-swa-1">
                        Your contact information
                      </h3>
                    </div>
                    <div className="space-y-6 sm:space-y-5">
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="name"
                          className={
                            errors.name
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Name
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            {...register("name", {
                              required: true,
                              maxLength: 100,
                            })}
                            type="text"
                            name="name"
                            id="name"
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                          />
                          {errors.name?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              A name is required
                            </p>
                          )}
                          {errors.name?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="company"
                          className={
                            errors.company
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Company Name
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            {...register("company", {
                              required: false,
                              maxLength: 100,
                            })}
                            placeholder="optional"
                            type="text"
                            name="company"
                            id="company"
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                          />

                          {errors.company?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="phone"
                          className={
                            errors.phone
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Contact number
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            {...register("phone", {
                              required: true,
                              maxLength: 20,
                            })}
                            id="phone"
                            name="phone"
                            type="text"
                            autoComplete="phone"
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3 sm:"
                          />
                          {errors.phone?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              A contact number is required
                            </p>
                          )}
                          {errors.phone?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="email"
                          className={
                            errors.email
                              ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                              : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                          }
                        >
                          Email address
                        </label>
                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                          <input
                            {...register("email", {
                              required: true,
                              maxLength: 100,
                              pattern:
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            })}
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            className="block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-swa-3 focus:border-swa-3 sm:"
                          />
                          {errors.email?.type === "required" && (
                            <p className="text-sm text-red-500 ">
                              Email address is required
                            </p>
                          )}
                          {errors.email?.type === "maxLength" && (
                            <span className="text-sm text-red-500">
                              You have exceeded maximum length of 100 characters
                            </span>
                          )}
                          {errors.email?.type === "pattern" && (
                            <span className="text-sm text-red-500">
                              Please enter a valid email format
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-6 space-y-6 sm:mt-5 sm:space-y-5">
             

                    <div className="grid-cols-1 sm:grid sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label
                        htmlFor="message"
                        className={
                          errors.message
                            ? `block font-medium text-red-500 sm:mt-px sm:pt-2 `
                            : `block font-medium text-gray-700 sm:mt-px sm:pt-2`
                        }
                      >
                        Message
                      </label>
                      <div className="relative mt-1 sm:mt-0 sm:col-span-2">
                        <textarea
                          {...register("message", {
                            required: true,
                            maxLength: 2000,
                          })}
                          id="message"
                          name="message"
                          rows={4}
                          className="block w-full pb-5 border border-gray-300 rounded-md shadow-sm focus:ring-swa-1 focus:border-swa-3"
                          defaultValue={""}
                        />
                        {errors.message?.type === "required" && (
                          <p className="text-sm text-red-500 ">
                            A message is required
                          </p>
                        )}
                        {errors.message?.type === "maxLength" && (
                          <p className="text-sm text-red-500 ">
                            2000 max characters allowed
                          </p>
                        )}
                        <span className="absolute right-0 text-xs text-gray-400 -bottom-5">
                          count: {count && count} (max 2000 char)
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-5">
                  <div className="flex justify-end">
                    {!isSubmitting && (
                      <button
                        type="submit"
                        disabled={
                          !isDirty ||
                          errors.name ||
                          errors.email ||
                          errors.phone ||
                          errors.message
                        }
                        className="inline-flex justify-center px-4 py-2 ml-3 font-medium text-white border border-transparent rounded-md shadow-sm bg-swa-1 hover:bg-swa-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-swa-3 disabled:opacity-30"
                      >
                        Submit
                      </button>
                    )}
                    {loading && (
                      <>
                        <Ring color="rgb(112,48,140)" />
                        <span className="font-bold text-md text-swa-3">
                          please wait...
                        </span>
                      </>
                    )}
                    {errorText !== "" && <TextError>{errorText}</TextError>}
                  </div>
                </div>
              </form>
            )}
          </div> */}
          <div className=" px-4 mx-auto mb-10 -mt-10">
            <div className="grid max-w-sm gap-5 mx-auto mt-2 lg:grid-cols-2 lg:max-w-none">
              <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                <div className="flex-shrink-0">
                  <a
                    href="https://www.tal.sg/tafep/contact-us"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="object-cover w-full h-full"
                      src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1660707005/formPages/ndt9d9dgdl1sw12ew2cm.webp"
                      alt="TAFEP"
                    />
                  </a>
                </div>
              </div>
              <div className="flex flex-col overflow-hidden rounded-lg shadow-lg">
                <div className="flex-shrink-0">
                  <a
                    href="https://actagainstviolence.org.sg/helplines/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      className="object-cover w-full h-full"
                      src="https://res.cloudinary.com/dkm14ge3g/image/upload/v1660711638/formPages/t0e7c5p7i7cndq8l1dvy.webp"
                      alt="ACT"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ContactForm;
